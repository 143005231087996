import styled, { css } from 'react-emotion';
import media from 'utils/mediaqueries';
import Link from 'components/Link';
import { sizes, colors } from 'components/Layout/variables';

export const SocialSharingStyled = styled('div')({
  position: 'relative',
  // Magic margin works as long as the container flexes
  marginLeft: 'auto',
  marginRight: 'auto',

  [media('md')]: {
    marginLeft: 'auto',
    marginRight: 0,
  },
});

export const LinkStyled = styled(Link)({
  textDecoration: 'none',
  transition: 'transform 0.25s ease-in-out, color .15s',
  display: 'inline-flex',
  fontSize: '1.6em',
  color: colors.greyLightAlt,
  padding: sizes.spacingQuarter,

  [media('md')]: {
    fontSize: '1.1em',
  },

  '&:hover, &:focus, &:active': {
    transform: 'translateY(-5px)',
    outline: '0',
    webkitTapHighlightColor: 'transparent',
  },

  '&:hover': {
    color: colors.greyMedium,
  },
});
