import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/Icon';

import { SocialSharingStyled, LinkStyled } from './styles';

const SocialSharing = ({ url }) => {
  if (typeof url !== 'string') {
    return null;
  }

  return (
    <SocialSharingStyled>
      <LinkStyled
        to={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
        title="Auf Facebook teilen"
      >
        <Icon name="facebook" />
      </LinkStyled>
      <LinkStyled
        to={`https://twitter.com/intent/tweet?hashtags=hazelbrugger&url=${url}`}
        title="Auf Twitter teilen"
      >
        <Icon name="twitter" />
      </LinkStyled>
    </SocialSharingStyled>
  );
};

PropTypes.propTypes = {
  url: PropTypes.string.isRequired,
};

export default SocialSharing;
