import React from 'react';
import styled from 'react-emotion';
import media from 'utils/mediaqueries';
import LinkButton from 'components/LinkButton';
import Headline from 'components/Headline';
import Image from 'components/Image';
import { WrapperStyled } from 'components/Wrapper/styles';
import { SectionStyled } from 'components/Craft/Modules/styles';
import { colors } from 'components/Layout/variables';

/**
 * Post header section.
 */
export const PostHeaderSectionStyled = styled(SectionStyled)(({ theme }) => ({
  marginBottom: 0,
  overflowX: 'hidden', // for the blockquote
}));

/**
 * Post body section.
 */
export const PostBodySectionStyled = styled(SectionStyled)(({ theme }) => ({
  marginBottom: theme.spacing,

  [media('lg')]: {
    marginBottom: theme.spacingDouble,
  },
}));

/**
 * Post body section.
 */
export const PostBodyWrapperStyled = styled(WrapperStyled)({
  [media('xl')]: {
    maxWidth: 900,
    paddingLeft: 0,
    paddingRight: 0,
  },
});

/**
 * PostEntry.
 * Inner content styles (from WYSIWYG)
 * Current tags from redactor:
 * h4, p, strong (bold), em (italics), a (link)
 */
export const PostEntryStyled = styled('div')(({ theme }) => ({
  p: {
    [media('sm')]: {
      marginBottom: theme.spacing,
    },
  },
  h4: {
    marginTop: theme.spacing,

    [media('sm')]: {
      marginTop: theme.spacingDouble,
    },
  },
}));

/**
 * Post title.
 */
export const PostEntryTitleStyled = styled(Headline)(({ theme }) => ({
  margin: `0 0 ${theme.spacing}`,
}));

/**
 * Post sub title.
 */
export const PostEntrySubTitleStyled = styled(Headline)({
  marginBottom: 10,
});

/**
 * Post meta wrapper.
 */
export const PostMetaWrapperStyled = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing,
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
  alignItems: 'center',
}));

/**
 * Post meta (date, author name).
 */
export const PostMetaStyled = styled('span')(
  {
    fontWeight: 'bold',
    fontSize: '.8em',
  },
  ({ theme }) => ({
    color: colors.greyLightAlt,
    marginRight: theme.spacingHalf,

    [media('md')]: {
      marginRight: theme.spacingDouble,
    },
  }),
);

/**
 * Post author wrap (avatar + name).
 */
export const PostAuthorWrapStyled = styled('span')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}));

export const PostAvatarImageStyled = styled('img')(({ theme }) => ({
  borderRadius: '50%',
  width: 36,
  height: 36,
  marginRight: theme.spacingHalf,
}));
