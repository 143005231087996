import React from 'react';
import moment from 'moment';
import {
  ImageStyled,
  PostsRelatedTileContentStyled,
  PostsRelatedTileStyled,
  PostsRelatedTileMetaStyled,
  PostsRelatedTileHeadline,
  PostsRelatedTileText,
} from './styles.js';

export default function PostsRelatedTile({ entry }) {
  const { postDate, postImage, title, postExcerpt, author, fullUri } = entry;
  return (
    <PostsRelatedTileStyled to={fullUri}>
      {postImage[0] && (
        <ImageStyled
          id={postImage[0].id}
          src={postImage[0].postHeaderThumbUrl}
          alt={postImage[0].title}
        />
      )}
      <PostsRelatedTileContentStyled>
        <PostsRelatedTileHeadline element="h4">
          {title}
        </PostsRelatedTileHeadline>
        <PostsRelatedTileText>{postExcerpt}</PostsRelatedTileText>
        {author?.fullName && (
          <PostsRelatedTileMetaStyled>
            {author?.fullName}
          </PostsRelatedTileMetaStyled>
        )}
        {postDate && (
          <PostsRelatedTileMetaStyled>
            {moment
              .unix(postDate)
              .locale('de')
              .format('D. MMM YYYY')}
          </PostsRelatedTileMetaStyled>
        )}
      </PostsRelatedTileContentStyled>
    </PostsRelatedTileStyled>
  );
}
