import React from 'react';

import PostsRelatedTile from 'components/PostsRelatedTile';

import {
  PostsRelatedWrapperStyled,
  PostsRelatedTitleStyled,
  PostsRelatedSubTitleStyled,
  PostsRelatedContentStyled,
} from './styles.js';

export default function PostsRelated({ entries }) {
  return entries?.length > 0 ? (
    <PostsRelatedWrapperStyled>
      <PostsRelatedSubTitleStyled element="h5">
        02 - Unterstützt durch Produktplazierungen
      </PostsRelatedSubTitleStyled>
      <PostsRelatedTitleStyled>Ähnliche Beiträge</PostsRelatedTitleStyled>
      <PostsRelatedContentStyled>
        {entries.map(entry => (
          <PostsRelatedTile key={entry?.id} entry={entry} />
        ))}
      </PostsRelatedContentStyled>
    </PostsRelatedWrapperStyled>
  ) : null;
}
