import React, { Component } from 'react';
import { graphql } from 'gatsby';
import Seo from 'components/Seo';
import PostEntry from 'components/PostEntry';

export default class Posts extends Component {
  render() {
    const { location, data } = this.props;
    const { postEntry } = data?.craft;
    const { seoTitle, seoDescription, seoImage } = data?.craft?.postEntry;

    if (!postEntry) return null;

    return (
      <>
        <Seo
          url={location?.href}
          title={seoTitle || postEntry.title}
          description={seoDescription || postEntry.postExcerpt}
          image={seoImage?.length > 0 ? seoImage : postEntry.postImage}
        />
        <section id={`post-${postEntry.slug}`}>
          <PostEntry postEntry={postEntry} postUrl={location.href} />
        </section>
      </>
    );
  }
}

export const postQuery = graphql`
  query PostQueryById($id: [Int]!) {
    craft {
      postEntry: entry(id: $id, section: [posts]) {
        id
        slug
        ... on Craft_PostsPost {
          postExcerpt
          ...PostEntryQuery
          # seo fields
          seoTitle
          seoDescription
          seoImage {
            id
            socialMediaUrl: url(transform: transformSocialMedia)
          }
        }
      }
    }
  }
`;
