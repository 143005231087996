import styled from 'react-emotion';
import media from 'utils/mediaqueries';
import { WrapperStyled } from 'components/Wrapper/styles';
import Headline from 'components/Headline';

export const PostsRelatedWrapperStyled = styled(WrapperStyled)({
  [media('lg')]: {
    maxWidth: 900,
    paddingLeft: 0,
    paddingRight: 0,
  },
});

export const PostsRelatedTitleStyled = styled(Headline)({
  marginTop: 0,
});

export const PostsRelatedSubTitleStyled = styled(Headline)({
  marginBottom: 10,
});

export const PostsRelatedContentStyled = styled('div')({
  display: 'flex',
  flex: '1 0 0',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
});
