import React from 'react';
import { graphql } from 'gatsby';
import moment from 'moment';
import 'moment/locale/de';
import Image from 'components/Image';
import PostsRelated from 'components/PostsRelated';
import PageBuilder from 'components/Craft/PageBuilder';
import SocialSharing from './SocialSharing';
import {
  PostEntryStyled,
  PostEntryTitleStyled,
  PostEntrySubTitleStyled,
  PostHeaderSectionStyled,
  PostBodySectionStyled,
  PostBodyWrapperStyled,
  PostMetaWrapperStyled,
  PostAuthorWrapStyled,
  PostAvatarImageStyled,
  PostMetaStyled,
} from './styles';

export default function PostEntry({ postEntry, postUrl }) {
  const {
    title,
    postSubtitle,
    postImage,
    author,
    postDate,
    postRelated,
    pageBuilder,
  } = postEntry;

  const postDateFormatted = moment
    .unix(postDate)
    .locale('de')
    .format('D. MMM YYYY');

  return (
    <PostEntryStyled>
      <PostHeaderSectionStyled>
        {postImage &&
          postImage.map(image => (
            <Image
              key={image.id}
              id={image.id}
              src={image.postHeaderThumbUrl}
              alt={image.title}
              srcSet={{ md: image.postHeaderUrl }}
            />
          ))}
      </PostHeaderSectionStyled>
      <PostBodySectionStyled hideOverflow>
        <PostBodyWrapperStyled>
          {postSubtitle && (
            <PostEntrySubTitleStyled element="h5">
              {postSubtitle}
            </PostEntrySubTitleStyled>
          )}
          <PostEntryTitleStyled element="h1">{title}</PostEntryTitleStyled>
          <PostMetaWrapperStyled>
            <SocialSharing url={postUrl} />
          </PostMetaWrapperStyled>
          <PageBuilder pageBuilder={pageBuilder} />
        </PostBodyWrapperStyled>
        <PostsRelated entries={postRelated} />
      </PostBodySectionStyled>
    </PostEntryStyled>
  );
}

export const postEntryQuery = graphql`
  fragment PostEntryQuery on Craft_PostsPost {
    id
    title
    slug
    postDate
    postSubtitle
    postImage {
      id
      title
      postHeaderUrl: url(transform: transformPostHeader)
      postHeaderThumbUrl: url(transform: transformPostHeaderThumb)
      socialMediaUrl: url(transform: transformSocialMedia)
    }
    postRelated {
      ... on Craft_PostsPost {
        id
        postDate
        slug
        postExcerpt
        fullUri
        postImage {
          id
          title
          postHeaderUrl: url(transform: transformPostHeaderThumb)
          postHeaderThumbUrl: url(transform: transformPostHeaderThumb)
        }
        title
        author {
          fullName
        }
      }
    }
    pageBuilder {
      ...PageBuilderQuery
    }
    author {
      fullName
      # Quickfix, had to be removed because it didn't work with cms updates. ~RS
      # photo {
      #   id
      #   url
      #   avatar: url(
      #     crop: { width: 100, height: 100, position: centerCenter, quality: 90 }
      #   )
      # }
    }
  }
`;
