import styled from 'react-emotion';
import media from 'utils/mediaqueries';
import Image from 'components/Image';
import Headline from 'components/Headline';
import Text from 'components/Text';
import Link from 'components/Link';
import { sizes } from 'components/Layout/variables';

export const PostsRelatedTileStyled = styled(Link)({
  display: 'block',
  textDecoration: 'none',
  flexBasis: '100%',
  marginBottom: sizes.spacing,

  [media('lg')]: {
    flexDirection: 'column',
    flexBasis: '48%',
  },
});

export const ImageStyled = styled(Image)({
  borderRadius: sizes.radius,
  overflow: 'hidden',
});

export const PostsRelatedTileContentStyled = styled('div')({
  padding: 24,
});

export const PostsRelatedTileHeadline = styled(Headline)({
  margin: '0 !important',
});

export const PostsRelatedTileText = styled(Text)({});

export const PostsRelatedTileMetaStyled = styled('span')(
  {
    fontWeight: 'bold',
    fontSize: '.8em',
    opacity: 0.5,
  },
  ({ theme }) => ({
    color: theme.grey,
    marginRight: theme.spacingDouble,
  }),
);
